import Sparkline from 'react-sparkline-svg';

export default function Statitic({ title, value, label, subText, sparkline, loading=false, action }) {
    if (loading) {
        return  (
            <div className="rounded bg-gray-300 flex h-32">
                &nbsp;
            </div>
        )
    }
    return (
        <div className="box relative flex flex-col items-center justify-center p-3 h-32">
            <div className="absolute top-0 mt-12 left-0 right-0 bottom-0 opacity-25">
                {sparkline &&
                    <Sparkline values={sparkline} stroke="green" />
                }
            </div>
            <div className="flex items-center">
                <p className="mr-3 tracking-tighter">{title}</p>
                {label &&
                    <span class="text-sm font-medium bg-green-200 py-1 px-2 rounded text-green-800 font-bold align-middle">{label}</span>
                }
            </div>
            { typeof value !== 'undefined' &&
                <p className="text-4xl font-bold tracking-tighter">{value}</p>
            }
            { subText &&
                <p className="text-sm tracking-tight">{subText}</p>
            }
            { action &&
                <div className="mt-2">
                    {action}
                </div>
            }
        </div>
    )
}
